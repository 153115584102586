// Packages
import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
	CheckCircleIcon,
	CalendarIcon,
	DocumentIcon,
	CodeIcon,
	AdjustmentsIcon,
	CloudIcon,
	DatabaseIcon,
	DeviceMobileIcon,
	LockClosedIcon,
	ShoppingCartIcon,
} from "@heroicons/react/outline";

// Types
import type SiteMetadata from "~types/SiteMetadata";
import type GatsbyImagePropsMeta from "~types/GatsbyImagePropsMeta";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import type Projekt from "~types/Projekt";

// Components
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import Layout from "~components/layouts/Layout";
import About from "~components/sections/landingpages/About";
import H2 from "~components/typography/H2";
import P from "~components/typography/P";
import Button from "~components/common/Button";
import BlurbSphere from "~components/features/BlurbSphere";
import CardProjektSimple from "~components/common/CardProjektSimple";
import FeatureList from "~components/sections/landingpages/FeatureList";
import CtaSection from "~components/sections/Cta";
import Disclosure from "~components/common/Disclosure";
import Header from "~components/sections/landingpages/v2/Header";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata,
	};
	seoImage: {
		facebook: GatsbyImagePropsMeta,
		twitterAndProduct: GatsbyImagePropsMeta,
	};
	imageHeaderDesigns: IGatsbyImageData;
	imageHeaderAndreas: IGatsbyImageData;
	workflowImage: IGatsbyImageData;
	imageKonzept: IGatsbyImageData;
	imageDigitalisierung: IGatsbyImageData;
	allProjekte: {
		nodes: Projekt[],
	};
}

const keyword = "Webdesign Kassel";
const title = "Webdesign Kassel ↗️ Dein zuverlässiger Partner für das Web";
const description =
	"Webdesign Kassel | Webdesigner für Konzeption, Design & Programmierung erfolgreicher Internetseiten ☎️ 0156 783 723 78 ▻ Beratung & Angebot!";

export default function Page({ data, location }: { data: DataProps, location: Location }) {
	const imageWorkflow = getImage(data.workflowImage);
	const imageKonzept = getImage(data.imageKonzept);
	const imageDigitalisierung = getImage(data.imageDigitalisierung);

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<Header
				schema={{
					name: keyword,
					image: data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src,
					description: description,
					offerType: "aggregateOffer",
					price: "500",
					priceTo: "5000",
					url: `${data.site.siteMetadata.siteUrl}${location.pathname}`,
				}}
				pages={[
					{ name: "Leistungen", to: "/leistungen/" },
					{
						name: keyword,
						to: location.pathname,
					},
				]}
				images={{
					image: {
						data: data.imageHeaderAndreas,
						alt: "Webdesign Kassel: Andreas Lautenschlager im Portrait",
						className: "max-w-sm",
						classNameDiv: "max-w-sm",
					},
					imageBackground: {
						data: data.imageHeaderDesigns,
						alt: "Webdesign Kassel: Designbeispiele",
						className: "max-w-2xl",
					},
				}}
				benefits={["Höchste Ansprüche", "100% Zufriedenheit", "Inhaltserstellung", "Individuelle Designs"]}
			>
				<>
					<h1 className="mb-5 font-display text-2xl font-bold md:text-4xl">
						Webdesign Kassel: professionell und einfach zu Deiner Website
					</h1>
					<P className="mb-8">
						Du suchst <strong>Webdesign Kassel</strong> für Dein Business? Wir planen, designen und entwickeln Websites
						individuell nach Deinen Bedürfnissen. Lautenschlager Marketing & Entwicklung ist eine Internetagentur aus
						Kassel, die zukunftsorientierte und skalierbare Lösungen <u>ohne Baukasten</u> und Softwareabhängigkeiten
						umsetzt. Du findest hier zuverlässige Webdesigner, gepaart mit einer agilen Werbeagentur.
					</P>

					<Button
						text="Termin vereinbaren"
						href="https://termin.lautenschlager.de/"
						target="_blank"
						rel="noopener noreferrer"
						className="mb-3 mr-3"
						brand
					/>
					<Button text="Kostenloses Beratungsgespräch vereinbaren" to={`${location.pathname}anfragen/`} />
				</>
			</Header>

			<Container>
				<div className="mx-auto mb-8 max-w-xl md:mb-16 md:text-center">
					{imageWorkflow && (
						<GatsbyImage image={imageWorkflow} alt="Webdesign Kassel: Workflow" title="Webdesign Kassel: Workflow" />
					)}
					<H2 className="mb-4 font-display" display>
						Wir erledigen das
					</H2>
					<P>
						Wenn Du eine Agentur suchst, die eine professionelle Website nach Deinen Vorstellungen programmieren soll,
						dann komme zu uns!
					</P>
				</div>
				<Grid cols={3}>
					<BlurbSphere
						number="1"
						title="Erstgespräch"
						text="Buche einen Termin und wir besprechen ausführlich wie Du Dir Deine Website vorstellst."
						svg={<CalendarIcon />}
					/>
					<BlurbSphere
						number="2"
						title="Angebot und Design"
						text="Erhalte ein Angebot und einen Designvorschlag innerhalb von 3-5 Werktagen."
						svg={<DocumentIcon />}
					/>
					<BlurbSphere
						number="3"
						title="Entwicklung und Go-Live"
						text="Wir entwickeln Deine Website bis Du zu 100 % zufrieden bist."
						svg={<CodeIcon />}
					/>
				</Grid>
			</Container>

			<Container>
				<div className="mx-auto mb-8 max-w-xl md:mb-16 md:text-center">
					<H2 className="mb-4 font-display" display>
						Projekte
					</H2>
					<P>
						Jedes Projekt ist so individuell, wie das Business-Modell dahinter. Das möchten wir durch individuelle
						Designs und Lösungen widerspiegeln. Entdecke unsere aktuellen Website-Projekte und überzeuge Dich selbst.
					</P>
				</div>
				<div className="bg-pattern grid gap-3 p-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{data.allProjekte.nodes.map((projekt) => (
						<CardProjektSimple
							title={projekt.title}
							image={projekt.remoteImage}
							href={projekt.link}
							key={projekt.title}
						/>
					))}
				</div>
			</Container>

			<FeatureList
				headline={`${keyword}: Unser Leistungsspektrum`}
				text={`Uns sind ganzheitliche Lösungen für unsere Kunden über aus wichtig. Daher bieten wir auch für den Bereich ${keyword} eine ganzheitliche Lösung für Deine Website. Ein einfacher Auftrag genügt und wir kümmern uns um die erfolgreiche Projektumsetzung und darüber hinaus.`}
				features={data.site.siteMetadata.globalFeautureList.webdesign}
			/>

			<CtaSection
				title="Jetzt Website anfragen"
				button="Termin vereinbaren"
				href="https://termin.lautenschlager.de/"
				text="Erhalte den besten Service und ein eine professionelle Website in einer unschlagbaren Zeit. Vereinbare dazu einfach einen Temrin."
				titleAs="p"
				target="_blank"
				rel="noopener noreferrer"
			/>

			<Container className="my-8 space-y-8 md:space-y-16 lg:space-y-32">
				<Grid cols={2}>
					<div>
						<H2 display className="mb-5 font-display">
							So arbeiten wir
						</H2>

						<P className="mb-3">
							In zahlreichen Gesprächen mit Interessenten aus Kassel ist uns besonders aufgefallen, dass viele ihr
							Budget in die falsche Person oder Dienstleistung investiert haben. Es wurde wochen- oder sogar monatelang
							mit einem unpassenden Dienstleister zusammengearbeitet, welcher ein sehr geringes Know-how hatte und
							dadurch das <strong>Webdesign</strong> und Marketing nicht professionell umsetzen konnte. Für den Kunden
							heißt das meistens eine zusätzliche Belastung und Zeitverschwendung.
						</P>
						<P>
							Um dem abzuhelfen, wollen wir von Anfang an klar kommunizieren, dass Du Dich auf uns verlassen kannst. Wir
							sind Dein zuverlässiger Partner für das Web. Wir möchten Deinen Auftrag nicht einfach nur abarbeiten,
							sondern Dein Business ganzheitlich betrachten und Dir Lösungen rund um das Thema Webdesign Kassel,
							Marketing und Digitalisierung anbieten. Deswegen geben wir Dir von Anfang an folgendes Versprechen.
						</P>
					</div>

					{imageKonzept && (
						<div>
							<GatsbyImage
								image={imageKonzept}
								alt={`Webdesign Kassel - Wireframe einer Website auf Mobil`}
								title={`Webdesign Kassel - Wireframe einer Website auf Mobil`}
							/>
						</div>
					)}
				</Grid>

				<Grid cols={3} itemsStart>
					<Disclosure
						title="Konzeption"
						text="Deine Webseite muss neben reiner Präsenz auch ein Ziel verfolgen. Wir planen und analysieren Dein Business und konzeptionieren eine Website, die auf Dein Geschäft und Deine Zielgruppe spezialisiert ist."
					/>
					<Disclosure
						title="Logo Design"
						text="Eine gute Brand ist essenziell für Dein Webdesign Kassel. Dein Logo wird immer auf der Website präsent angezeigt und ist daher essenziell für den ersten Eindruck. Solltest Du kein Logo haben, dann gestalten wir bei Bedarf Deine komplette Corporate Identitiy. Dabei definiert Ihr gemeinsam, wie Dein komplette Auftreten auszusehen hat. Es geht dabei um Typografie, Farben, Bilderwelten und vieles mehr."
					/>
					<Disclosure
						title="Webdesign"
						text="Anhand der Konzeption und Deines Brands planen und gestalten wir Dein Webdesign Kassel sowohl für Mobil als auch für den Desktop. Dich erwartet zeitloses und Responsive Design, gepaart mit robuster Technik. Wir optimieren Deine Website sofort für jegliche SEO Maßnahmen und schauen so, dass Dein Unternehmen exzellent aufgestellt ist. Du erhältst von uns die schnellsten und individuellsten Websites, die Du je gesehen hast. Deine Kunden werden Deine Website lieben."
					/>
					<Disclosure
						title="Projektmanagement"
						text="Der richtige Ansprechpartner ist entscheidend für die erfolgreiche Projektentwicklung von Webdesign Kassel. Bei uns hast Du nur einen Ansprechpartner für alle Fragen. So sparst Du Dir die Kommunikation mit unterschiedlichen Personen und hast einen festen Ansprechpartner für Deine Projekte. Zusätzlich realisieren wir unsere Projekte in einem SCRUM-Zyklus. So können wir alle zwei Wochen Ergebnisse liefern, die begeistern werden."
					/>
					<Disclosure
						title="Technik"
						text="Zusammengeflickte Systemen und Plugins gehören der Vergangenheit an. Bei uns erhältst Du eine saubere Website, die von echten Designern und Programmierern erstellt wurde. Zu Webdesign in Kassel gehören auch Performance, Sicherheit und Skalierung. Die schnellsten und individuellsten Websites bekommst Du bei uns, gepaart mit erfolgreichem Marketing. Zusätzlich binden wir auch Deine vorhandenen Systeme per API-Schnittstelle an, sodass Du bei der Digitalisierung Deiner Website ganz vorne mitspielst."
					/>
					<Disclosure
						title="SEO / SEA Marketing"
						text="Die meisten möchten eine Website, um mehr Reichweite zu generieren. Als digitale Werbeagentur arbeiten wir ein Marketingkonzept aus, sodass Du auch auf Google und sozialen Medien gefunden werden kannst. Dabei spezialisieren wir uns auf Suchmaschinenoptimierung / SEO und Suchmaschinenwerbung / SEA und kombinieren das elegant mit Content-Marketing. So generieren wir für Dich einen automatischen Kunden-Strom, sodass Deine Website auch als erfolgreiches Marketing-Werkzeug benutzt werden kann und Dein Unternehmen an Sichtbarkeit erhält."
					/>
				</Grid>
			</Container>
			<Container className="my-8 space-y-8 md:space-y-16 lg:space-y-32">
				<Grid cols={2}>
					<div>
						<H2 display className="mb-5 font-display">
							Wir digitalisieren und bauen Brücken
						</H2>

						<P className="mb-3">
							Wer heute ein Unternehmen in Kassel führt, der digitalisiert am besten sein Unternehmen mit unserer
							Dienstleistung Webdesign Kassel, um wettbewerbsfähig zu bleiben. Mit uns als kompetente Werbeagentur bist
							Du an der richtigen Stelle, wenn es um digitale Lösungen geht. Wir verknüpfen vorhandene CRM-Systeme,
							binden vorhandene Services ein und bauen eigene Backends für unsere Kunden.
						</P>
						<P>
							Um einige Beispiele zu nennen, findest Du anbei einige Use Cases, wie wir Dir mit Webdesign helfen
							könnten:
						</P>
					</div>

					{imageDigitalisierung && (
						<div>
							<GatsbyImage
								image={imageDigitalisierung}
								alt={`Webdesign Kassel - Schachfiguren auf digitalen Netzwerk`}
								title={`Webdesign Kassel - Schachfiguren auf digitalen Netzwerk`}
							/>
						</div>
					)}
				</Grid>

				<Grid cols={3}>
					<BlurbSphere
						number="1"
						title="CMS"
						titleAs="p"
						text="Ein CMS	ist eine Lösung, wie man Content erstellt und verwaltet. So kannst Du Inhalte an
								auf Deiner Website selbst verwalten."
						svg={<AdjustmentsIcon />}
					/>
					<BlurbSphere
						number="2"
						title="Service-Anbindungen"
						text="Du nutzt schon einen von vielen branchenspezifischen Serviceanbieter? Mit dem Paket für Webdesign Kassel möchten wir Dich vorwiegend in allen digitalisierungs Fragen unterstützten. Unsere langjährige Erfahrung erlaubt es und Dein Serviceanbieter an Deine Website anzubinden, um so Prozesse zu automatisieren."
						svg={<CloudIcon />}
						titleAs="p"
					/>
					<BlurbSphere
						number="3"
						title="Loginsysteme"
						text="Deine Software soll nicht öffentlich zugänglich sein, aber dennoch von überall erreichbar sein? Mit einer webbasierten Webdesign Kassel Lösung können wir so Services und Inhalte anbieten, die Du ganz einfach über den Browser abrufen kannst."
						svg={<LockClosedIcon />}
						titleAs="p"
					/>
					<BlurbSphere
						number="4"
						title="Datenbanken"
						text="Daten erstellen, ändern und lesen ist essenziell. Mit unseren Lösungen bieten wir Dir ein Datenbanksystem welches API-First ist. So können wir Daten von überall einfließen lassen und auswerten."
						svg={<DatabaseIcon />}
						titleAs="p"
					/>
					<BlurbSphere
						number="5"
						title="Apps und Mobile Endgeräte"
						text="Responsive Design ist für uns eine Selbstverständlichkeit. Wir gehen einen Schritt weiter und bauen gerne auch native Apps für Mobile Endgeräte, die auch auf Gerätefunktionen zugreifen."
						svg={<DeviceMobileIcon />}
						titleAs="p"
					/>
					<BlurbSphere
						number="6"
						title="Onlineshop"
						text="Als Shopify Partner sind wir der richtige Ansprechpartner, wenn es darum geht Deine Homepage durch einen digitalen Verkaufskanal zu erweitern. Dabei integrieren den Onlineshop von Shopify nahtlos in das Webdesign Deiner Website ein."
						svg={<ShoppingCartIcon />}
						titleAs="p"
					/>
				</Grid>
			</Container>

			<Container className="my-8 space-y-8 md:space-y-16 lg:space-y-32">
				<Grid cols={2}>
					<div>
						<H2 display className="mb-5 font-display">
							Marketing
						</H2>

						<P className="mb-3">
							Bei den meisten, die eine Website, ist das Ziel klar: Mehr Reichweite zu generieren, damit mehr Besucher
							auf der Website landen. Als digitale Werbeagentur kombinieren wir unser Fachwissen im Bereich Webdesign
							Kassel und arbeiten gemeinsam mit der SEO Premium Agentur ein Marketingkonzept aus, sodass Du auf Google
							und sozialen Medien gefunden werden kannst. Dabei spezialisieren wir uns auf Suchmaschinenoptimierung /
							SEO und Suchmaschinenwerbung / SEA und kombinieren das elegant mit Content-Marketing. So generieren wir
							für Dich einen automatischen Kunden-Strom über die Suchmaschinen, sodass Deine Website auch als
							erfolgreiches Marketing-Werkzeug benutzt werden kann und Dein Unternehmen an Sichtbarkeit erhält.
						</P>
						<P>
							Mit uns wirst Du sichtbar im Internet und generierst automatisch mehr Besucher. Aber Achtung:
							Suchmaschinen sind ständig im Wandel und die Optimierung einer Website, darf nicht abflachen. Eine
							dauerhafte Betreuung von einer professionellen Agentur für Dein Unternehmen ist dabei wichtig. Eine
							ständige Optimierung Deiner Website und Inhalte ist nötig, um Deine Zielgruppe dauerhaft zu erreichen. Mit
							Webdesign Kassel buchst Du zudem eine Beratungsleistung, die Dir aufzeigst, was Du machen musst und dem
							Wandel gerecht zu werden.
						</P>
					</div>

					<div>
						<H2 display className="mb-5 font-display">
							Unsere Technik
						</H2>

						<P className="mb-3">
							Monolithische Systeme wie WordPress und Typo3 sind Webdesign von gestern. Unser Webdesign Kassel Paket
							basiert auf dem{" "}
							<Link to="/blog/was-ist-jamstack-die-neue-architektur-fuer-websites/" className="underline">
								JAMStack-Modell
							</Link>
							. Diese Art der Webentwicklung kennzeichnet sich durch hoch skalierbare und schnelle Websites, die nicht
							von einzelner Software abhängig sind. Das ist besonders für kleine und mittelständische Unternehmen
							wichtig, da diese besonders am Anfang dynamisch bleiben müssen. In Kombination mit einer bequemen
							Entwicklungsumgebung können wir so Deine Website in kürzester Zeit realisieren und anpassen.
						</P>
						<P className="mb-3">
							Mit uns wirst Du sichtbar im Internet und generierst automatisch mehr Besucher. Aber Achtung:
							Suchmaschinen sind ständig im Wandel und die Optimierung einer Website, darf nicht abflachen. Eine
							dauerhafte Betreuung von einer professionellen Agentur für Dein Unternehmen ist dabei wichtig. Eine
							ständige Optimierung Deiner Website und Inhalte ist nötig, um Deine Zielgruppe dauerhaft zu erreichen. Mit
							Webdesign Kassel buchst Du zudem eine Beratungsleistung, die Dir aufzeigst, was Du machen musst und dem
							Wandel gerecht zu werden.
						</P>
						<div className="space-y-3">
							<Disclosure
								title="Webdesign Kassel bedeutet bei uns extrem kurze Ladezeiten"
								text="Gestaltung und Webdesign sind nicht alles. Die Geschwindigkeit hat einen essenziellen Einfluss auf die Benutzererfahrung und Kundengenerierung auf Deiner Website. JAMStack-Websites machen rasante Websites möglich, indem statische Assets vor dem Website-Aufruf generiert wurden und so die Serverauslastung minimiert. So erreichen alle unsere Websites einen Google Pagespeed von über 90 - das bedeutet, dass die Website meist unter einer halben Sekunde lädt.

						"
							/>
							<Disclosure
								title="Einfache und preiswerte Skalierung"
								text="Mit einem Content Delivery Network werden unsere Websites global zwischengespeichert. Das versichert einfacheren Bereitstellungen, integrierte Redundanzen und unglaubliche Belastbarkeit. Websites, welches nicht von einem zentralen Server abhängig ist, sichert Dich vor einem Serverausfall und unterstützen gutes Webdesign. Falls ein Server mal ausfällt, springt ein weiterer Server anstelle des ausgefallenen Servers und liefert so die Website aus. Mit dem Webdesign Kassel Paket bist Du also bei uns immer auf der sicheren Seite."
							/>
							<Disclosure
								title="Schnelle Ergebnisse"
								text="Wir bauen auf eigen entwickelten Systemen und Komponenten auf, statt Templates aus dem Internet. So können wir Dir echtes Webdesign auf Basis unserer Konzepte anbieten und machen uns unabhängig von überladenen Systemen. Unsere Kunden können mit rasanten Änderungen und individuelle Webdesign-Lösungen rechnen, die nicht durch Technik oder Unwissenheit ausgebremst werden."
							/>
						</div>
					</div>
				</Grid>
			</Container>

			<About link={`${location.pathname}anfragen/`} keyword={keyword} />
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "pages/leistungen/webdesign-kassel/webdesign-kassel_seo-image.png" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		imageHeaderDesigns: file(
			relativePath: { eq: "pages/leistungen/webdesign-kassel/webdesign-kassel_header-designs.png" }
		) {
			childImageSharp {
				gatsbyImageData(quality: 80, width: 672)
			}
		}
		imageHeaderAndreas: file(
			relativePath: { eq: "pages/leistungen/webdesign-kassel/webdesign-kassel_header-andreas.png" }
		) {
			childImageSharp {
				gatsbyImageData(quality: 80, width: 384)
			}
		}
		workflowImage: file(relativePath: { eq: "pages/leistungen/webdesign-kassel/webdesign-kassel_workflow.png" }) {
			childImageSharp {
				gatsbyImageData(width: 320)
			}
		}

		imageKonzept: file(relativePath: { eq: "pages/leistungen/webdesign-kassel/webdesign-kassel_konzept.jpg" }) {
			childImageSharp {
				gatsbyImageData(transformOptions: { grayscale: true })
			}
		}

		imageMarketing: file(relativePath: { eq: "pages/leistungen/webdesign-kassel/webdesign-kassel_marketing.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageTechnik: file(relativePath: { eq: "pages/leistungen/webdesign-kassel/webdesign-kassel_technik.jpg" }) {
			childImageSharp {
				gatsbyImageData
			}
		}

		imageDigitalisierung: file(
			relativePath: { eq: "pages/leistungen/webdesign-kassel/webdesign-kassel_digitalisierung.jpeg" }
		) {
			childImageSharp {
				gatsbyImageData
			}
		}

		site {
			siteMetadata {
				siteUrl
				globalFeautureList {
					webdesign {
						headline
						text
					}
				}
			}
		}

		allProjekte {
			nodes {
				title
				remoteImage {
					childImageSharp {
						gatsbyImageData(width: 711)
					}
				}
				link
				tech {
					value
				}
			}
		}
	}
`;
