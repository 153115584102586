import { Disclosure as DisclosureLibary, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import React from "react";

interface Props {
	title: string;
	text?: string;
	children?: React.ReactNode;
}

const Disclosure: React.FC<Props> = ({ title, text, children }) => {
	return (
		<DisclosureLibary as="div">
			{({ open }) => (
				<>
					<DisclosureLibary.Button className="w-full p-4 text-lg font-bold bg-white border">
						<div className="flex items-center justify-between">
							<dt className="text-left">{title}</dt>
							<div>
								<ChevronRightIcon className={`h-4 ${open ? "transform rotate-90" : ""}`} />
							</div>
						</div>
					</DisclosureLibary.Button>
					<Transition
						show={open}
						enter="transition transform ease duration-200"
						enterFrom="opacity-0 -translate-y-4"
						enterTo="opacity-100 translate-y-0"
						leave="transition ease-in duration-150"
						leaveFrom="opacity-100  translate-y-0"
						leaveTo="opacity-0 -translate-y-4"
						unmount={false}
					>
						<DisclosureLibary.Panel as="dd" className="max-w-full p-4" unmount={false}>
							{text}
							{children}
						</DisclosureLibary.Panel>
					</Transition>
				</>
			)}
		</DisclosureLibary>
	);
};

export default Disclosure;
