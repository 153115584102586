import { ExternalLinkIcon } from "@heroicons/react/solid";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import H2 from "~components/typography/H2";
import H3 from "~components/typography/H3";
import Button from "./Button";

interface Props {
	title: string;
	image: IGatsbyImageData;
	href: string;
}

const CardProjekt: React.FC<Props> = ({ title, image, href }) => {
	const imageRes = getImage(image);

	return (
		<div className="relative">
			{imageRes ? <GatsbyImage image={imageRes} alt={`${title} Projekt`} /> : null}
			<a href={href} rel="noopener dofollow" target="_blank" className="group">
				<div className="absolute w-full h-full bg-white bg-opacity-90 inset-0 opacity-0 group-hover:opacity-100 duration-300 flex items-center justify-center">
					Zur Website<span className="sr-only"> von {title} </span>
					<ExternalLinkIcon className="inline w-4 h-4 ml-3" />
				</div>
			</a>
		</div>
	);
};

export default CardProjekt;
